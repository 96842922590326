import Issuance from '@/classes/Issuance.js';
import { getToken as getTokenFromStorage, getTokenDecoden } from '@/helpers/tokenauth.js'; 
import { getAgeByDate, formatIsoDate, getCurrentDate } from '@/helpers/dates.js';
import { getLocalStorage, getSessionStorage } from '@/helpers/storage.js';
import api from '@/api/api';

export default class VtIssuance extends Issuance {

    token_auth= "";
    vt_policy_request= {
      foncabsa_vt_quote_id: 1,
      foncabsa_user_id: 0,
      contractor_rfc: this.contractor.rfc,
      contractor_name: this.contractor.name,
      contractor_paternal_lastname: this.contractor.paternal_name,
      contractor_maternal_lastname: this.contractor.maternal_name,
      contractor_street: this.contractor.street,
      contractor_st_number: this.contractor.ext_number,
      contractor_neighborhood: this.contractor.suburb,
      contractor_postal_code: this.contractor.postal_code,
      contractor_city: this.contractor.city,
      contractor_state_name: this.contractor.state,
      contractor_state_code: "",
      contractor_emails: this.contractor.email,
      contractor_curp: this.contractor.curp,
      contractor_gender: this.contractor.gender,
      contractor_public_office: "",//TODO: PREGUNTAR
      contractor_st_interior_number: (this.contractor.int_number == null)? '': this.contractor.int_number,
      contractor_municipality: this.contractor.municipality,
      contractor_phone: this.contractor.phone,
      contractor_cellphone: this.contractor.cellphone,
      contractor_profession: this.contractor.occupation,
      contractor_country: 'MÉXICO',
      contractor_company_name: (this.contractor.company_name == "-----" || this.contractor.company_name == "")? "N/A": this.contractor.company_name,
      insured_rfc: this.insured.rfc,
      insured_name: this.insured.name,
      insured_paternal_lastname: this.insured.paternal_name,
      insured_maternal_lastname: this.insured.maternal_name,
      insured_street: this.insured.street,
      insured_st_number: this.insured.ext_number,
      insured_neighborhood: this.insured.suburb,
      insured_postal_code: this.insured.postal_code,
      insured_city: this.insured.city,
      insured_state_name: this.insured.state,
      insured_state_code: "",
      insured_emails: this.insured.email,
      insured_curp: this.insured.curp,
      insured_st_interior_number: this.insured.int_number,
      insured_phone: this.insured.phone,
      insured_cellphone: this.insured.cellphone,
      insured_municipality: this.insured.municipality,
      insured_marital_status: this.insured.marital_status,
      insured_public_office: this.insured.public_office,
      insured_country: "MÉXICO",
      insured_profession: this.insured.occupation,
      insured_city_of_birth: this.insured.city_of_birth,
      insured_state_name_birth: this.insured.state_name_birth,
      insured_date_birth: this.insured.date_birth,
      insured_height: this.insured.height,
      insured_weight: this.insured.weight,
      insured_gender: this.insured.gender,
      processed: false,
      bank_name: "",
      card_type: "",
      card_number: "",
      expiration_date: "",
      account_type_bank: "",
      reference_gnp: 0,
      insured_company_name: (this.work.company_name == "-----" || this.work.company_name == "")? "N/A": this.work.company_name,
      date_canceled: "1900-01-01 00:00:00",
      period_payment: this.collection.payment_frequency,
      conduit_payment: (this.collection.payment_method == '')? 'nomina': this.collection.payment_method,
      start_validity: getCurrentDate(),
      quote_ocupations: [...this.work.occupation],
      quote_diseases: this.quote.diseases,
      quote_narcotics: this.getNarcoticsList(),
      quote_activities: [...this.quote.hobbies],
      quote_smokes: this.quote.is_smoker,
      quote_decease: "",
      quote_assured_sum:  this.quote.assured_amount,
      quote_disability: "",
      quote_disability_no_wait: "",
      quote_annual_savings: "",
      quote_exemption_prime: "",
      quote_total_savings: "",
      quote_diba_assured_sum: "",
      quote_diba_prime_annual: "",
      quote_diba_prime_frequency: "",
      quote_extra_prime_percentage: "",
      beneficiaries: this.generateArrayBeneficiaries(this.beneficiaries, `${this.quote.person.name} ${this.quote.person.middle_name}`, this.quote.person.paternal_name, this.quote.person.maternal_name)
    };

    constructor(values){
        super({
            id: values.id,
            client_id: values.client_id,
            user_id: values.user_id,
            beneficiaries: values.beneficiaries,
            rfc: values.rfc,
            curp: values.curp,
            name: values.name,
            middle_name: values.middle_name,
            paternal_name: values.paternal_name,
            maternal_name: values.maternal_name,
            email: values.email,
            telephone: values.telephone,
            gender: values.gender,
            age: values.age,
            birthday: values.birthday,
            cellphone: values.cellphone,
            is_smoker: values.is_smoker,
            smoker: values.smoker,
            narcotics: values.narcotics,
            is_additional_insured: values.is_additional_insured,
            assured_amount: values.assured_amount,
            alcohol: values.alcohol,
            narcotics: values.narcotics,
            coverages: values.coverages,
            diseases: values.diseases,
            occupations: values.occupations,
            sports: values.sports,
            hobbies: values.hobbies,
            activities: values.activities,
            surgery: values.surgery,
            weight: values.weight,
            height: values.height,
            city_of_birth: values.city_of_birth,
            state_name_birth: values.state_name_birth,
            payment_frequency: values.payment_frequency,
            payment_method: values.payment_method,
            relationship: values.relationship,
            contractor_rfc: values.contractor.rfc,
            contractor_name: values.contractor.name,
            contractor_paternal_name: values.contractor.paternal_name,
            contractor_maternal_name: values.contractor.maternal_name,
            contractor_street: values.contractor.street,
            contractor_ext_number: values.contractor.ext_number,
            contractor_int_number: values.contractor.int_number,
            contractor_suburb: values.contractor.suburb,
            contractor_postal_code: values.contractor.postal_code,
            contractor_city: values.contractor.city,
            contractor_state: values.contractor.state,
            contractor_email: values.contractor.email,
            contractor_curp: values.contractor.curp,
            contractor_gender: values.contractor.gender,
            contractor_municipality: values.contractor.municipality,
            contractor_phone: values.contractor.phone,
            contractor_cellphone: values.contractor.cellphone,
            contractor_occupation: values.contractor.occupation,
            contractor_country: values.contractor.country,
            contractor_company_name: values.contractor.company_name,
            contractor_annual_amount: values.contractor.annual_amount,
            insured_rfc: values.insured.rfc,
            insured_name: values.insured.name,
            insured_paternal_name: values.insured.paternal_name,
            insured_maternal_name: values.insured.maternal_name,
            insured_street: values.insured.street,
            insured_suburb: values.insured.suburb,
            insured_postal_code: values.insured.postal_code,
            insured_city: values.insured.city,
            insured_state: values.insured.state,
            insured_state_code: values.insured.state_code,
            insured_email: values.insured.email,
            insured_curp: values.insured.curp,
            insured_ext_number: values.insured.ext_number,
            insured_int_number: values.insured.int_number,
            insured_phone: values.insured.phone,
            insured_cellphone: values.insured.cellphone,
            insured_municipality: values.insured.municipality,
            insured_marital_status: values.insured.marital_status,
            insured_public_office: values.insured.public_office,
            insured_country: values.insured.country,
            insured_occupation: values.insured.occupation,
            insured_city_of_birth: values.insured.city_of_birth,
            insured_state_name_birth: values.insured.state_name_birth,
            insured_date_birth: values.insured.date_birth,
            insured_height: values.insured.height,
            insured_weight: values.insured.weight,
            insured_gender: values.insured.gender,
            documents_list: values.documents_list
        });
    }

    execute(){
        return this.createJson();
    }

    createJson() {
        const { token_auth, vt_policy_request } = this;
        return { token_auth, vt_policy_request };
    }  

    async post(params){        

        const quotation = getLocalStorage('quote-description');

        const list_documents = [
            getLocalStorage('document_ine') == undefined ? undefined : Object.assign(getLocalStorage('document_ine')[0], {abbreviation: "INE"}), 
            getLocalStorage('document_proof_of_address') == undefined ? undefined : Object.assign(getLocalStorage('document_proof_of_address')[0], {abbreviation: "CD"})
        ].filter(x=>x);

        const accountInformation = getTokenDecoden();
        const token = getTokenFromStorage('ccapi');
        const url = `${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_policy/register`;

        params.token_auth = token;
        params.vt_policy_request.foncabsa_user_id = accountInformation.obj.personnel_info.foncabsa_user_id;
        params.vt_policy_request.foncabsa_vt_quote_id = quotation.quote_id;
        params.vt_policy_request.quote_decease = quotation.data.fallecimiento; //TODO: fallecimiento
        params.vt_policy_request.quote_disability = quotation.data.prima_invalidez;//TODO: prima_invalidez
        params.vt_policy_request.quote_disability_no_wait = quotation.data.invalidez_sin_espera; //TODO: invalidez_sin_espera
        params.vt_policy_request.quote_annual_savings = 0; //TODO: vacio
        params.vt_policy_request.quote_exemption_prime = quotation.data.exencion_prima_ahorro;//TODO: exencion_prima_ahorro
        params.vt_policy_request.quote_total_savings = quotation.data.ahorro_total;//TODO: ahorro_total
        params.vt_policy_request.quote_diba_assured_sum = 0;//TODO: vacio
        params.vt_policy_request.quote_diba_prime_annual = quotation.data.prima_diba_anual;//TODO: prima_diba_anual
        params.vt_policy_request.quote_diba_prime_frequency = 0;//TODO: payment_frequency
        params.vt_policy_request.quote_extra_prime_percentage = (quotation.data.info_modifiers == null)? 0: quotation.data.info_modifiers.extraPrimePercentage;
        params.vt_policy_request.metadata = this.buildInfoMetadata();
        params.documents = []
        list_documents.forEach((doc)=> {
            params.documents.push({
                file: doc.base64_file,
                extension: doc.extension,
                abbreviation: doc.abbreviation
            });
        });
        
        
        const result = await this.createIssuance(token, params, url)

        const client_id = getSessionStorage('user-information').user_data.client_id
        if(client_id != "" && client_id != null && parseInt(client_id) > 0){
            await this.createProductVt(token, client_id)
        }

        return result;
    }

    generateArrayBeneficiaries(beneficiaries, executor_name, executor_maternal_name, executor_paternal_name) {
        return beneficiaries.map(beneficiary => {
            const age = getAgeByDate(beneficiary.birthdate);
            return {
                "name": beneficiary.name || beneficiary.names,
                "paternal_lastname": beneficiary.paternal_name,
                "maternal_lastname": beneficiary.maternal_name,
                "relationship": beneficiary.relationship.toLowerCase(),
                "birth_date": formatIsoDate(beneficiary.birthdate, '/'),
                "percentage": beneficiary.percentage,
                "insured_interest": "",
                "executor_name": (age<18)? executor_name: "",
                "executor_paternal_lastname": (age<18)? executor_maternal_name: "",
                "executor_maternal_lastname": (age<18)? executor_paternal_name: "",
                "executor_relationship": "",
            }
        })
    }

    getNarcoticsList(){
        let narcoticsList = [];

        if(this.quote.narcotics.type!=""){
            narcoticsList.push(this.quote.narcotics.type)
        }
        
        if(this.quote.smoker.you_consumes!=""){
            narcoticsList.push(this.quote.smoker.you_consumes)
        }

        return narcoticsList;
    }

    buildInfoMetadata(){

        const quoteVtInfo = getLocalStorage('quote-description');
        const issuanceVidatotal = getLocalStorage('issuance-vida-total');

        const metadata_product_vt = {
            product_create_json: {
                client_id: "0",
                quote_id: quoteVtInfo.quote_id,
                vt_info: {
                    amount_cents: quoteVtInfo.data.prima_total*100,
                    current_balance_cents: "0",
                    current_continous_payments: "",
                    date_of_first_payment: "1900-01-01",
                    date_of_last_payment: "1900-01-01",
                    initiation_date: "1900-01-01",
                    expiration_date: "1900-01-01",
                    payroll_frequency: issuanceVidatotal.collection.payment_frequency.toLowerCase() == "" ? "quincenal" : issuanceVidatotal.collection.payment_frequency.toLowerCase(),
                    periods_without_payments: "",
                    cancellation_date: "",
                    policy_number: "",
                    vt_ise_attributes: {
                        amount_cents: quoteVtInfo.data.invalidez_sin_espera*100,
                        current_balance_cents: "0",
                        current_continous_payments: "",
                        date_of_first_payment: "1900-01-01",
                        date_of_last_payment: "1900-01-01",
                        periods_without_payments: "0",
                        policy_number: "",
                        cancellation_date: ""
                    },
                    vt_bit_attributes: {
                        amount_cents: quoteVtInfo.data.prima_invalidez*100,
                        current_balance_cents: "0",
                        current_continous_payments: "24",
                        date_of_first_payment: "1900-01-01",
                        date_of_last_payment: "1900-01-01",
                        periods_without_payments: "0",
                        policy_number: "",
                        cancellation_date: ""
                    },
                    vt_death_attributes: {
                        amount_cents: quoteVtInfo.data.fallecimiento*100,
                        current_balance_cents: "0",
                        current_continous_payments: "24",
                        date_of_first_payment: "1900-01-01",
                        date_of_last_payment: "1900-01-01",
                        periods_without_payments: "0",
                        policy_number: "",
                        cancellation_date: ""
                    }
                }
            }
        }

        if(quoteVtInfo.data.ahorro_total > 0){
            metadata_product_vt.product_create_json.vt_info.vt_bitah_attributes = {
                amount_cents: quoteVtInfo.data.exencion_prima_ahorro*100,
                current_balance_cents: "0",
                current_continous_payments: "24",
                date_of_first_payment: "1900-01-01",
                date_of_last_payment: "1900-01-01",
                periods_without_payments: "0",
                policy_number: "",
                cancellation_date: ""
            }
    
            metadata_product_vt.product_create_json.vt_info.vt_savings_attributes = {
                amount_cents: quoteVtInfo.data.ahorro_total*100,
                current_balance_cents: "0",
                current_continous_payments: "24",
                date_of_first_payment: "1900-01-01",
                date_of_last_payment: "1900-01-01",
                periods_without_payments: "0",
                policy_number: "",
                cancellation_date: ""
            }
        }

        return metadata_product_vt
    }

    async createProductVt(token, client_id) {

        const json = {
            token_auth: token,
            client_id: client_id
        }
    
        const response = await api.httpPost(`${process.env.VUE_APP_CCAPI}/${process.env.VUE_APP_BUSINESS_FONCABSA}/vt_policy/register_product_with_policy_request`, {}, json);
    
        return response.response;
    
    }
}