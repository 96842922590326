<template>
    <Loading :isLoading="isLoading" />
    <html>
      <body class="" style="background: #FFF">
        <Header/>
        <MenuLateralMobile/>
        <div class="h-screen grid grid-cols-6 grid-rows-12 grid-flow-col">
           <MenuLateral/>
            <div class="bg-container col-span-6">
                <div class="flex ml-5 mt-5 cursor-pointer" @click="redirect_history()" >
                    <img src="@/assets/back-arrow.svg">
                    Atrás
                </div>
                <div v-if="!continueQuote" class="w-full md:w-4/5 mx-auto h-auto py-10 md:py-16 grid grid-cols-1 md:grid-cols-2">
                    <div class="px-5">
                        <p class="text-2xl md:text-3xl font-bold mb-5 md:mb-10 RobotoBold">Resumen</p>
                        <div class="border shadow rounded border-gray-100 px-5 py-4">
                            <p class="text-lg font-light mb-2 RobotoBold">Datos Personales</p>  
                            
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">R.F.C.:</p> 
                                <p class="text-md font-light mb-2 RobotoLight">
                                    {{ detail.quote.person.rfc }}
                                </p>
                            </div>
                            
                            <p class="text-lg font-light mb-0 RobotoBold">Dirección</p>  
                            <div class="grid grid-cols-1 mb-4">
                                <p class="text-sm font-light RobotoLight">{{ `${detail.address.street} #${detail.address.ext_number}` }}</p>
                            </div>
                            <div class="grid grid-cols-2">
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">Colonia:</p> 
                                    <p class="text-md font-light RobotoLight">{{ detail.address.suburb }}</p>
                                </div>
                                <div class="mb-2">
                                    <p class="text-sm font-light RobotoLight">C.P.:</p> 
                                    <p class="text-md font-light RobotoLight">{{ detail.address.postal_code }}</p>
                                </div>
                            </div>
                            <div class="grid grid-cols-1">
                                <div class="mb-2">
                                    <p class="text-md font-light RobotoLight" >{{ (`${detail.address.city}, ${detail.address.state}, ${detail.address.country}`).toUpperCase() }}</p>
                                </div>
                            </div>
                            
                            <div class="mb-5 mt-5">
                                <p class="text-lg font-light mb-5 RobotoBold">Datos Laborales del Adicional</p>
                            </div>

                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Empresa donde trabaja:</p> 
                                <p class="text-md font-light RobotoLight">{{ detail.work.company_name }}</p>
                            </div>

                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Ocupación:</p> 
                                <p class="text-md font-light RobotoLight">{{ detail.work.occupation }}</p>
                            </div>

                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Ingresos Anuales:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatCurrency(detail.work.annual_amount) }}</p>
                            </div>

                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Cobranza</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Método de Pago:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatCamelize(detail.collection.payment_method) }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Frecuencia de Pago:</p> 
                                <p class="text-md font-light RobotoLight">{{ $filters.formatCamelize(detail.collection.payment_frequency) }}</p>
                            </div>

                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Beneficiarios</p></div>
                            <div class="mb-2 grid grid-rows-1 mb-2" v-for="(beneficiary, index) in detail.beneficiaries" :key="index">
                                <p class="text-xl font-light RobotoLight">{{ (`${beneficiary.names ||  beneficiary.name} ${beneficiary.paternal_name} ${beneficiary.maternal_name}`).toUpperCase() }}</p> 
                                <div class="grid grid-cols-2">
                                    <p class="text-md font-light RobotoLight capitalize">{{ beneficiary.relationship }} | {{ getYears(beneficiary.birthdate) }} años</p>
                                    <p class="flex text-md font-light RobotoLight capitalize justify-center">{{ beneficiary.percentage }}%</p>
                                </div>
                            </div>

                            <div class="mb-5 mt-5"><p class="text-lg font-light mb-5 RobotoBold">Documentos</p></div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">INE/IFE:</p> 
                                <p class="text-md font-light RobotoRegular" v-for="(document, index) in documentListIne" :key="index">{{ document.name }}</p>
                            </div>
                            <div class="mb-2">
                                <p class="text-sm font-light RobotoLight">Comprobante de domicilio:</p> 
                                <p class="text-md font-light RobotoRegular" v-for="(document, index) in documentListProofOfAddress" :key="index">{{ document.name }}</p>
                            </div>

                        </div>
                        <input type="button" id="button_next" class="bg-mustardDark cursor-pointer text-white px-5 py-2 mx-auto my-5 w-full h-14 md:h-14  rounded text-center font-medium text-xl" @click="confirm" value="Finalizar">
                    </div>
                    <div class="px-10 py-16 hidden md:block">
                        <img :src="require('@/assets/Cuenta/vidaSimple/finished.svg')" alt="FONCABSA SFC" class="mx-auto">
                    </div>
                </div>
            </div>
        </div>
      </body>
    </html>
</template>

<script>
import { onMounted, onBeforeMount, ref, getCurrentInstance } from 'vue';
import { useRouter, useRoute } from 'vue-router';

import Header from "@/components/Administrative/Header/Header.vue";
import MenuLateralMobile from '@/components/MenuLateralMobile.vue';
import MenuLateral from '@/components/LateralMenu.vue';
import { getTokenDecoden, getUserInfo } from '@/helpers/tokenauth.js';
import texts from "@/helpers/texts";
import { formatIsoDate } from '@/helpers/dates.js';

import moment from "moment";
import Swal from "sweetalert2";
import DataIssuance from "@/classes/DataIssuance";
import Issuance from "@/classes/Issuance";

import Loading from "@/components/Loading/VueLoading.vue";
import { getLocalStorage, createLocalStorage, getSessionStorage, getTokenOfSessionStorage } from '@/helpers/storage.js';

export default {
    setup(){

        let counterGoBack = ref(0);
        let continueQuote = ref(false);
        let detail = ref({});
        let companyName = ref('');
        let occupation = ref('');
        let annualIncome = ref(null);

        let app = getCurrentInstance();

        const isLoading = ref(true)
        const router = useRouter();
        const route = useRoute();
        const category = ref('');
        const role = ref('');

        const documentListIne = ref({});
        const documentListProofOfAddress = ref({});
        const contractingLocation = ref('');
        const quotationJson= ref();
        const issuanceJson= ref();
        const issuanceClass= ref();
        const dataIssuanceClass= ref();


        onBeforeMount(async () => {
            category.value = getCategory(false);
            role.value = getRole();
            
            if(storageExist(`quote-${category.value}`) && storageExist(`issuance-${category.value}`)){
                await loadInfo();
                return;
            }

            router.push({path: '/cuenta/principal'});
        });

        onMounted(async()=>{
            isLoading.value = false;
        });

        const setIssuanceData = () => {
            const occupation = getOccupationForm();
            const quotation = getQuoteFromStorage();
            const issuance = getIssuanceFromStorage();
            const userDetail = getSessionStorage('user-detail');
            const userInformation = getSessionStorage('user-information');

            const isAdditional = quotation.is_additional_insured.toLowerCase() == 'para adicional';
            issuanceClass.value.contractor.cellphone = quotation.person.cellphone; 
            issuanceClass.value.contractor.city = !isAdditional?  userInformation.user_data.city: issuance.address.city;
            issuanceClass.value.contractor.company_name = !isAdditional?  userInformation.work_info.business_name: issuance.work.company_name;
            issuanceClass.value.contractor.country = !isAdditional?  "MÉXICO" : issuance.address.country;
            issuanceClass.value.contractor.curp = quotation.person.curp; 
            issuanceClass.value.contractor.email = quotation.person.email; 
            issuanceClass.value.contractor.ext_number = !isAdditional?  userInformation.user_data.ext_number : issuance.address.ext_number; 
            issuanceClass.value.contractor.gender = quotation.person.gender; 
            issuanceClass.value.contractor.int_number = !isAdditional?  userInformation.user_data.int_number : issuance.address.int_number; 
            issuanceClass.value.contractor.maternal_name = quotation.person.maternal_name; 
            issuanceClass.value.contractor.municipality = !isAdditional?  userInformation.user_data.municipality : issuance.address.municipality;
            issuanceClass.value.contractor.name = !isAdditional?  texts.removeNullOrEmptyFromArray([userInformation.user_data.name, userInformation.user_data.middle_name]) : texts.removeNullOrEmptyFromArray([quotation.person.name, quotation.person.middle_name]); 
            issuanceClass.value.contractor.occupation = !isAdditional?  occupation.selection_list : issuance.work.occupation;
            issuanceClass.value.contractor.annual_amount = !isAdditional?  issuance.work.annual_amount : (userDetail.salary_info.monthly_income!=null)? (parseInt(userDetail.salary_info.monthly_income) * 12) : 0;
            issuanceClass.value.contractor.paternal_name = quotation.person.paternal_name; 
            issuanceClass.value.contractor.phone =  quotation.person.phone;  
            issuanceClass.value.contractor.postal_code = !isAdditional?  userInformation.user_data.postal_code : issuance.address.postal_code;
            issuanceClass.value.contractor.rfc = quotation.person.rfc; 
            issuanceClass.value.contractor.state = !isAdditional?  userInformation.user_data.state: issuance.address.state;
            issuanceClass.value.contractor.street = !isAdditional?  userInformation.user_data.street: issuance.address.street;
            issuanceClass.value.contractor.suburb  = !isAdditional?  userInformation.user_data.suburb: issuance.address.suburb;
            
            issuanceClass.value.insured.rfc = quotation.person.rfc;
            issuanceClass.value.insured.name = texts.removeNullOrEmptyFromArray([quotation.person.name, quotation.person.middle_name]); 
            issuanceClass.value.insured.paternal_name = quotation.person.paternal_name;
            issuanceClass.value.insured.maternal_name = quotation.person.maternal_name;
            issuanceClass.value.insured.street = issuance.address.street;
            issuanceClass.value.insured.suburb = issuance.address.suburb;
            issuanceClass.value.insured.postal_code = issuance.address.postal_code;
            issuanceClass.value.insured.city =  issuance.address.city;
            issuanceClass.value.insured.state =  issuance.address.state;
            issuanceClass.value.insured.state_code = "";
            issuanceClass.value.insured.email = quotation.person.email;
            issuanceClass.value.insured.curp = quotation.person.curp;
            issuanceClass.value.insured.ext_number = issuance.address.ext_number; 
            issuanceClass.value.insured.int_number = issuance.address.int_number; 
            issuanceClass.value.insured.phone = quotation.person.phone; 
            issuanceClass.value.insured.cellphone = quotation.person.cellphone;
            issuanceClass.value.insured.municipality = issuance.address.municipality;
            issuanceClass.value.insured.marital_status = quotation.marital_status;
            issuanceClass.value.insured.public_office = "";
            issuanceClass.value.insured.country = issuance.address.country;
            issuanceClass.value.insured.occupation = issuance.work.occupation;
            issuanceClass.value.insured.city_of_birth = quotation.person.city_of_birth;
            issuanceClass.value.insured.state_name_birth = quotation.person.state_name_birth;
            issuanceClass.value.insured.date_birth = quotation.person.birthday;
            issuanceClass.value.insured.height = quotation.height;
            issuanceClass.value.insured.weight = quotation.weight;
            issuanceClass.value.insured.gender = quotation.person.gender;

            issuanceJson.value = {
                ...issuanceJson.value.quote,
                ...issuanceJson.value.quote.person,
                ...issuanceJson.value.address,
                ...issuanceJson.value.collection,
                ...issuanceJson.value.work,
                contractor: issuanceClass.value.contractor,
                insured: issuanceClass.value.insured,
                beneficiaries: issuanceJson.value.beneficiaries,
                documents_list:issuanceJson.value.documents_list
            };
            console.log("🚀 ~ file: Detail.vue:239 ~ setIssuanceData ~ issuanceJson.value.documents_list:", issuanceJson.value.documents_list)
            console.log("setIssuanceData ~ issuanceJson.value:", issuanceJson.value)
            dataIssuanceClass.value = dataIssuanceClass.value.new(getCategory(), issuanceJson.value);
            console.log("🚀 ~ file: Detail.vue:241 ~ setIssuanceData ~ dataIssuanceClass.value:", dataIssuanceClass.value.execute())
            issuanceJson.value = dataIssuanceClass.value.execute(); 
            console.log("🚀 ~ file: Detail.vue:242 ~ setIssuanceData ~ issuanceJson.value :", issuanceJson.value )
        }

        const getOccupationForm = () => {
            return getLocalStorage(`quote-occupations`)
        }

        const getQuoteFromStorage = () => {
            const product = getCategory(false);
            return getLocalStorage(`quote-${product}`);
        }

        const getIssuanceFromStorage = () => {
            const product = getCategory(false);
            return getLocalStorage(`issuance-${product}`);
        }

        const getCategory = (replace = true) => {
            let product = route.params.product;
            return replace? product.replaceAll("-", " "): product;
        }

        const getRole = (replace = true) => {
            let role = route.params.role;
            return role;
        }

        const getYears = (birthday) => {
            birthday = formatIsoDate(birthday, '/')
            birthday = moment(birthday);
            let today = moment();
            let age = today.diff(birthday,"years");
            return age;
        }

        const loadInfo = async() => {
            documentListIne.value = getLocalStorage('document_ine');
            documentListProofOfAddress.value = getLocalStorage('document_proof_of_address');

            app = app.appContext.config.globalProperties;
            issuanceJson.value = getLocalStorage(`issuance-${category.value}`);
            quotationJson.value = getLocalStorage(`quote-${category.value}`);
            issuanceJson.value.quote = quotationJson.value;
            detail.value = issuanceJson.value;
            issuanceClass.value = new Issuance({});
            dataIssuanceClass.value = new DataIssuance();
            setIssuanceData();            
        }
        
        const storageExist = (name) => {
            return (localStorage.getItem(name))? true: false;
        }

        const next = () => {
            if(role.value!=null && role.value!=""){
                if(category.value == "vida-total"){
                    Swal.fire({
                        title: "Aviso",
                        html: 'Emisión generada de forma exitosa',
                        icon: "success",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    }).then(() => {
                        router.push(`/${role.value}/productos/${category.value}`)
                    });

                    return;
                }
            }

            router.push({path:'finalizar'})
        }

        const confirm = async () => {
            isLoading.value = true;

            console.log("response ~ issuanceJson.value:", issuanceJson.value)
            const response = await dataIssuanceClass.value.post(issuanceJson.value).then(response => response.data.response.data ).catch(error => {
                if( error.response.status != 500) {
                    Swal.fire({
                        title: "Aviso",
                        html: error.response.data.response.message,
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }else{
                    Swal.fire({
                        title: "Aviso",
                        html: "Servicio de cotización no disponible, por favor inténtelo de nuevo más tarde",
                        icon: "info",
                        confirmButtonText: "OK",
                        confirmButtonColor: '#FEB72B'
                    });
                }

                return null;
            });
            
            if(response){
                createLocalStorage('issuance-detail', response);
                removeItemsStorage();
                next();
            } 

            isLoading.value = false;
        }

        function removeItemsStorage(){
            const storaceList = ["document_proof_of_address", "issuance", "quote", "quote-data", "issuance-applicant-address",  "issuance-data", "quote-description", "document_ine"];

            storaceList.forEach(key => {
                localStorage.removeItem(localStorage.key(key));
            })
        }

        const redirect_history = () => router.go(-1);

        return {
            category,
            counterGoBack,
            continueQuote,
            next,
            detail,
            confirm,
            isLoading,
            getYears,
            documentListIne,
            documentListProofOfAddress,
            contractingLocation,
            companyName,
            occupation,
            annualIncome,
            redirect_history
        }
    },
    components:{
        Header,
        MenuLateralMobile,
        MenuLateral,
        Loading
    }
}
</script>

<style scoped>
.description {
    font-family: 'roboto-regular';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 33px;
    color: #485462;
}
</style>