import Issuance from '@/classes/Issuance.js';
import { getToken as getTokenFromStorage } from '@/helpers/tokenauth.js'; 
import { getAgeByDate, formatIsoDate, getCurrentDate, getYearsRemaining, getYears, addYearsToCurrentDate, subtractDateByNumberOfLapses } from '@/helpers/dates.js';
import { formatUppercase } from '@/helpers/filters.js';
export default class VsIssuance extends Issuance {

    application_date = getCurrentDate();
    location = formatUppercase(`${ this.contractor.city }, ${ this.contractor.municipality }, ${ this.contractor.state }`);
    start_date = getCurrentDate();
    end_date = addYearsToCurrentDate(getYearsRemaining(this.quote.person.birthday, 99));
    is_renewal = true;
    retainer_number = 11;
    type_of_delivery = "correo asegurado";
    applicant_information = {
        name: formatUppercase(this.insured.name),
        middle_name:  formatUppercase(""),
        paternal_name: formatUppercase(this.insured.paternal_name),
        maternal_name: formatUppercase(this.insured.maternal_name),
        rfc: formatUppercase(this.insured.rfc),
        curp: formatUppercase(this.insured.curp),
        street: formatUppercase(this.insured.street),
        number_ext: this.insured.ext_number,
        number_int: this.insured.int_number,
        suburb: formatUppercase(this.insured.suburb),
        city: formatUppercase(this.insured.city),
        state: formatUppercase(this.insured.state),
        municipality: formatUppercase(this.insured.municipality),
        postal_code: this.insured.postal_code,
        cellphone: this.insured.cellphone,
        phone: this.insured.phone,
        place_of_birth: formatUppercase(this.insured.suburb),
        date_of_birth: formatUppercase(this.insured.date_birth),
        age: getAgeByDate(this.insured.date_birth),
        gender: this.insured.gender,
        marital_status: this.insured.marital_status.replace('(a)', ''),
        annual_income: this.work.annual_amount,
        email: this.insured.email,
        occupation: this.work.occupation,
        description_occupation: "",
        company_name: formatUppercase(this.work.company_name),
        workplace: "",
        type_of_activity: "",
        renewable_beneficiaries: false
    };

    contracting_party = {
        name: formatUppercase(this.contractor.name),
        middle_name: formatUppercase(this.contractor.middle_name),
        paternal_name: formatUppercase(this.contractor.paternal_name),
        maternal_name: formatUppercase(this.contractor.maternal_name),
        rfc: formatUppercase(this.contractor.rfc),
        curp: formatUppercase(this.contractor.curp),
        street: formatUppercase(this.contractor.street),
        number_ext: this.contractor.ext_number,
        number_int: this.contractor.int_number,
        suburb: formatUppercase(this.contractor.suburb),
        city: formatUppercase(this.contractor.city),
        state: formatUppercase(this.contractor.state),
        municipality: formatUppercase(this.contractor.municipality),
        postal_code: this.contractor.postal_code,
        cellphone: this.contractor.cellphone,
        phone: this.contractor.phone,
        email: formatUppercase(this.contractor.email),
        annual_income: this.work.annual_amount,
        occupation: this.work.occupation,
        company_name: formatUppercase(this.work.company_name),
        relationship_with_applicant: formatUppercase(this.quote.relationship),
        gender: this.contractor.gender,
        tax_regime: "",
        tax_regime_description: "",
        tax_rfc: "",
        tax_social_name: "",
        tax_date_of_constitution: ""
    };

    coverage_information = {
        vitalicio: (this.quote.coverages.includes('vitalicio'))? true: false,
        vitalicio_assured_amount: (this.quote.assured_amount)? parseInt(this.quote.assured_amount) : 0,
        vitalicio_national_currency: true,
        vitalicio_term_reached_age: true,
        vitalicio_term_years_of_insurance: 1,
        vitalicio_term_years_of_payment: 1,
        dotal: (this.quote.coverages.includes('dotal'))? true: false,
        dotal_number_months: 12,
        dotal_monthly_price: 249,
        bit: (this.quote.coverages.includes('bit'))? true: false,
        bit_disability_and_permanent_payments: (this.quote.coverages.includes('bit'))? true: false,
        bpai: (this.quote.coverages.includes('bpai'))? true: false,
        bpai_assured_amount: (this.quote.coverages.includes('bpai'))? this.quote.assured_amount : 0,
        ma: (this.quote.coverages.includes('ma'))? true: false,
        ma_assured_amount: (this.quote.coverages.includes('ma'))? this.quote.assured_amount: 0,
        di: (this.quote.coverages.includes('di'))? true: false,
        di_assured_amount: (this.quote.coverages.includes('di'))? this.quote.assured_amount: 0,
        ti: (this.quote.coverages.includes('ti'))? true: false,
        ti_assured_amount: (this.quote.coverages.includes('ti'))? this.quote.assured_amount: 0,
        eg: (this.quote.coverages.includes('eg'))? true: false,
        eg_assured_amount: (this.quote.coverages.includes('eg'))? this.quote.assured_amount: 0,
        cpf: (this.quote.coverages.includes('cpf'))? true: false,
        cpf_assured_amount: (this.quote.coverages.includes('cpf'))? this.quote.assured_amount: 0,
        provisional: true,
        provisional_coverage_name: "",
        provisional_agent_key: "39423",
        provisional_folio_number: "",
        provisional_date: getCurrentDate(),
        provisional_assured_amount: parseInt(this.quote.assured_amount),
        provisional_names: formatUppercase(this.insured.name),
        provisional_paternal_name: formatUppercase(this.insured.paternal_name),
        provisional_maternal_name: formatUppercase(this.insured.maternal_name),
        payment_frequency: "mensual",
        payment_method: this.collection.payment_method,
        retainer: "CABSA"
    };

    beneficiaries = this.generateArrayBeneficiaries(this.beneficiaries);

    health_questions = {
        is_smoker: this.quote.is_smoker,
        type_smoker: this.quote.smoker.you_consumes,
        amount_smoker: this.quote.smoker.quantity,
        frequency_smoker: this.quote.smoker.frecuency,
        date_since_you_quit_smoking: this.quote.smoker.completion_date,
        use_drugs: this.quote.narcotics.consumes == "Sí"? true: false,
        using_drugs_since: this.quote.narcotics.consumption_time == ""? "1900-00-00": subtractDateByNumberOfLapses(parseInt(this.quote.narcotics.consumption_time.replace('año', '').replace('s', '').trim())),
        drug_type: this.quote.narcotics.type,
        drug_frequency: this.quote.narcotics.frecuency,
        last_time_using_drugs: this.quote.narcotics.last_consumption_date == ""? "1900-00-00": this.quote.narcotics.last_consumption_date,
        received_treatment_related_to_drug_use: this.quote.narcotics.treatment == "Sí"? true: false,
        is_alcoholic: this.quote.alcohol.consumes == "Sí"? true: false,
        amount_of_alcoholic_beverage: this.quote.alcohol.quantity,
        frequency_of_alcoholic_beverage: this.quote.alcohol.frecuency,
        date_since_stopped_drinking_alcohol: this.quote.alcohol.completion_date == ""? "1900-00-00": formatIsoDate(this.quote.alcohol.completion_date, '/'),
        p1_height: parseInt(this.quote.height.toString().replaceAll('.','')),
        p2_weight: parseInt(this.quote.weight.toString().replaceAll('.','')),
        p8_had_surgery: this.quote.surgery.length > 0,
        p12_pregnancy: false,
        p12_have_had_preeclampsia: false,
        p12_have_had_eclampsia: false,
        p12_have_had_an_embarazo_ectopico_etc: false
    };

    documents = {
        identificacion_oficial: this.getDocumentExist('INE'),
        identificacion_oficial_url: this.getDocumentByTag('INE'),
        comprobante_domicilio: this.getDocumentExist('COMPROBANTE-DOMICILIO'),
        comprobante_domicilio_url: this.getDocumentByTag('COMPROBANTE-DOMICILIO'),
        calidad_migratoria: false,
        calidad_migratoria_url: "",
        comprobante_incripcion: false,
        comprobante_incripcion_url: "",
        curp: false,
        curp_url: "",
        rfc: false,
        rfc_url: ""
    };

    agent_details = {
        info1: {
            name: formatUppercase("daniel"),
            office: formatUppercase("oficina"),
            key_code: 39423,
            participation: formatUppercase("pendiente"),
            promotoria: formatUppercase("pendiente")
        },
        observations: "",
        p1_date_of_knowing_the_applicant: getCurrentDate(),
        p2_identity_verification_type: "Presencial",
        p3_completed_client_request: true,
        p3_negative_answer: "",
        p4_recommended_client: true,
        p4_negative_answer: ""
    };

    quote_information = {
        age: getYears(this.quote.person.birthday),
        gender: (this.quote.person.gender == 'masculino')? 'male' : 'female',
        is_smoker: this.quote.is_smoker,
        assured_amount: parseInt(this.quote.assured_amount),
        coverages: this.quote.coverages,
        diseases: this.quote.diseases,
        occupations: this.quote.occupations,
        sports: this.quote.sports,
        activities: this.quote.activities,
        height: parseInt(this.quote.height.toString().replaceAll('.','')),
        weight: parseInt(this.quote.weight.toString().replaceAll('.','')),
    };

    constructor(values){
        super({
            id: values.id,
            client_id: values.client_id,
            user_id: values.user_id,
            beneficiaries: values.beneficiaries,
            rfc: values.rfc,
            curp: values.curp,
            name: values.name,
            middle_name: values.middle_name,
            paternal_name: values.paternal_name,
            maternal_name: values.maternal_name,
            email: values.email,
            telephone: values.telephone,
            gender: values.gender,
            age: values.age,
            birthday: values.birthday,
            cellphone: values.cellphone,
            is_smoker: values.is_smoker,
            smoker: values.smoker,
            narcotics: values.narcotics,
            is_additional_insured: values.is_additional_insured,
            assured_amount: values.assured_amount,
            alcohol: values.alcohol,
            narcotics: values.narcotics,
            coverages: values.coverages,
            diseases: values.diseases,
            occupations: values.occupations,
            sports: values.sports,
            hobbies: values.hobbies,
            activities: values.activities,
            surgery: values.surgery,
            weight: values.weight,
            height: values.height,
            city_of_birth: values.city_of_birth,
            state_name_birth: values.state_name_birth,
            payment_frequency: values.payment_frequency,
            payment_method: values.payment_method,
            relationship: values.relationship,
            contractor_rfc: values.contractor.rfc,
            contractor_name: values.contractor.name,
            contractor_paternal_name: values.contractor.paternal_name,
            contractor_maternal_name: values.contractor.maternal_name,
            contractor_street: values.contractor.street,
            contractor_ext_number: values.contractor.ext_number,
            contractor_int_number: values.contractor.int_number,
            contractor_suburb: values.contractor.suburb,
            contractor_postal_code: values.contractor.postal_code,
            contractor_city: values.contractor.city,
            contractor_state: values.contractor.state,
            contractor_email: values.contractor.email,
            contractor_curp: values.contractor.curp,
            contractor_gender: values.contractor.gender,
            contractor_municipality: values.contractor.municipality,
            contractor_phone: values.contractor.phone,
            contractor_cellphone: values.contractor.cellphone,
            contractor_occupation: values.contractor.occupation,
            contractor_country: values.contractor.country,
            contractor_company_name: values.contractor.company_name,
            contractor_annual_amount: values.contractor.annual_amount,
            insured_rfc: values.insured.rfc,
            insured_name: values.insured.name,
            insured_paternal_name: values.insured.paternal_name,
            insured_maternal_name: values.insured.maternal_name,
            insured_street: values.insured.street,
            insured_suburb: values.insured.suburb,
            insured_postal_code: values.insured.postal_code,
            insured_city: values.insured.city,
            insured_state: values.insured.state,
            insured_state_code: values.insured.state_code,
            insured_email: values.insured.email,
            insured_curp: values.insured.curp,
            insured_ext_number: values.insured.ext_number,
            insured_int_number: values.insured.int_number,
            insured_phone: values.insured.phone,
            insured_cellphone: values.insured.cellphone,
            insured_municipality: values.insured.municipality,
            insured_marital_status: values.insured.marital_status,
            insured_public_office: values.insured.public_office,
            insured_country: values.insured.country,
            insured_occupation: values.insured.occupation,
            insured_city_of_birth: values.insured.city_of_birth,
            insured_state_name_birth: values.insured.state_name_birth,
            insured_date_birth: values.insured.date_birth,
            insured_height: values.insured.height,
            insured_weight: values.insured.weight,
            insured_gender: values.insured.gender,
            documents_list: values.documents_list
        });
    }

    execute(){
        return this.createJson();
    }

    createJson() {
        const { application_date, location, start_date, end_date, is_renewal, retainer_number, type_of_delivery, applicant_information, contracting_party, coverage_information, beneficiaries, health_questions, documents, agent_details, quote_information } = this;
        return { application_date, location, start_date, end_date, is_renewal, retainer_number, type_of_delivery, applicant_information, contracting_party, coverage_information, beneficiaries, health_questions, documents, agent_details, quote_information };
    }  

    post(params){
        console.log("post ~ params:", params)
        const token = getTokenFromStorage('gsapi');
        const url = `${process.env.VUE_APP_GSAPI}/vs/application/create`;
        return this.createIssuance(token, params, url)
    }

    generateArrayBeneficiaries(beneficiaries) {
        return beneficiaries.map(beneficiary => {
            return { 
                "name": beneficiary.name || beneficiary.names,
                "middle_name": "",
                "paternal_name": beneficiary.paternal_name,
                "maternal_name": beneficiary.maternal_name,
                "relationship": beneficiary.relationship.toLowerCase(),
                "percentage": beneficiary.percentage,
                "date_of_birth": formatIsoDate(beneficiary.birthdate, '/'),
                "adress": ""
            }
        })
    }

    getDocumentByTag(tag) {
        if(!this.getDocumentExist(tag)) return "";
        const documents = this.documents_list.filter(document => document.name.includes(tag))
        let documentStringList = documents.map(document => document.url);
        documentStringList = documentStringList.join(', ');
        return documentStringList;
    }

    getDocumentExist(tag) {
        const documentsArray = this.documents_list.filter(document => document.name.includes(tag))
        return documentsArray.length > 0;
    }
}