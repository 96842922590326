import VsIssuance from '@/classes/VsIssuance';
import VtIssuance from '@/classes/VtIssuance';

export default class DataIssuance {
    new(type, values){
        if(type == 'vida simple'){
            return new VsIssuance(values);
        }else{
            return new VtIssuance(values);
        }
    }
}